import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "@reach/router";
import { useNavigate } from "/src/hooks/navigation";
import ConsultsComponent from "../../components/consults";
import { getConsultsThunk } from "../../redux/actions/consults-actions";

const ConsultsContainer = () => {
  const dispatch = useDispatch();
  const { navigate } = useNavigate();
  const { consultationStatus, page = 1 } = useParams();

  const { data, hasNext, hasPrevious, status } = useSelector(
    (state) => state.consults
  );
  const user = useSelector((state) => state.user.data);

  const handleRequestNextPage = () =>
    navigate(`/tenant/${consultationStatus}/${parseInt(page) + 1}`);

  const handleRequestPreviousPage = () =>
    navigate(`/tenant/${consultationStatus}/${parseInt(page) - 1}`);

  useEffect(() => {
    dispatch(getConsultsThunk({ status: consultationStatus, index: page }));
  }, [consultationStatus, page, dispatch]);

  return (
    <ConsultsComponent
      onNextPage={handleRequestNextPage}
      onPreviousPage={handleRequestPreviousPage}
      consults={data}
      currentUserId={user.id}
      hasNext={hasNext}
      hasPrevious={hasPrevious}
      status={status}
    />
  );
};

export default ConsultsContainer;
