import {
  Box,
  CircularProgress,
  Divider,
  Hidden,
  Link,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React, { useEffect, useState } from "react";
// theme
import theme from "../../theme";
import RetailIcon from "./../../icons/retail-icon";
import ConsultItem from "./consult-item";
import { Trans } from "@lingui/macro";

const useStyles = makeStyles((theme) => ({
  link: {
    color: theme.palette.black,
    fontWeight: "bold",
  },
  retailIcon: {
    width: 120,
    height: 120,
    opacity: 0.7,
  },
}));

function Consults({
  consults,
  status,
  onPreviousPage,
  onNextPage,
  currentUserId,
  hasNext,
  hasPrevious,
}) {
  const classes = useStyles(theme);
  const [height, setHeight] = useState();

  const handleNextPage = () => {
    if (hasNext) onNextPage(status);
  };

  const handleBeforePage = () => {
    if (hasPrevious) onPreviousPage(status);
  };

  const handleScreenResize = () => {
    const overhead = window.innerWidth < 999 ? 120 : 200;
    setHeight(window.innerHeight - 190);
    setHeight(window.innerHeight - overhead);
  };

  useEffect(() => {
    // mobile vs desktop
    // TODO: room for improvement
    // Remove this for the calc(100% - 200px)
    handleScreenResize();
    window.addEventListener("resize", handleScreenResize);
    return () => {
      window.removeEventListener("resize", handleScreenResize);
    };
  }, []);

  return (
    <Box pr={3} overflow={"auto"} height={height}>
      <Hidden smDown={true}>
        <Divider />
      </Hidden>
      {status == "success" &&
        consults.map((c) => (
          <React.Fragment key={c.id}>
            <ConsultItem
              consult={c}
              currentUserId={currentUserId}
            ></ConsultItem>
            <Hidden smDown={true}>
              <Divider />
            </Hidden>
          </React.Fragment>
        ))}
      {status === "success" && !consults.length && (
        <Box
          display={"flex"}
          flexDirection={"column"}
          alignItems={"center"}
          mt={theme.spacing(2)}
        >
          <RetailIcon className={classes.retailIcon} />
          <Typography variant={"h6"} style={{ textAlign: "center" }}>
            <Trans>
              Aún no tienes consultas en esta etapa. ¡Consulta a los
              propietarios y haz realidad tu idea!
            </Trans>
          </Typography>
        </Box>
      )}
      {status === "loading" ? (
        <Box display={"flex"} justifyContent={"center"} mt={theme.spacing(1)}>
          <CircularProgress color="primary" />
        </Box>
      ) : (
        <Box p={1} display={"flex"}>
          <Box p={0} flexGrow={1} flexDirection="flex-start">
            {hasPrevious && (
              <Link
                href="#"
                onClick={handleBeforePage}
                variant="button"
                className={classes.link}
              >
                {"<< "}
                <Trans>ANTERIOR</Trans>
              </Link>
            )}
          </Box>
          <Box p={0} flexDirection="flex-end">
            {hasNext && (
              <Link
                href="#"
                onClick={handleNextPage}
                variant="button"
                className={classes.link}
              >
                <Trans>MOSTRAR MÁS</Trans>
                {" >>"}
              </Link>
            )}
          </Box>
        </Box>
      )}
    </Box>
  );
}

export default Consults;
