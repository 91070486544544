import React from "react";
import { useSelector, useDispatch } from "react-redux";
import SpaceInfoPaperComponent from "../../components/space-info-paper";
import { showTenantBookingInvoices } from "../../redux/actions/tenant-booking-invoices-actions";

const SpaceInfoPaper = ({ goToPayment = () => {}, AdSpaceModal = () => <></>, showOnlyInfo = false, invoice = {} }) => {
  const dispatch = useDispatch();

  const booking = useSelector((state) => state.consultation.data);
  const isLoadingBooking = useSelector((state) => state.consultation.status);
  const space = useSelector((state) => state.consultation.data.listing);

  const handleSeeTenantBookingInvoices = () => dispatch(showTenantBookingInvoices(booking.bookingId));

  return (
    <SpaceInfoPaperComponent
      space={space}
      booking={booking}
      AdSpaceModal={AdSpaceModal}
      goToPayment={goToPayment}
      showOnlyInfo={showOnlyInfo}
      onSeeTenantBookingInvoices={handleSeeTenantBookingInvoices}
      isLoadingBooking={isLoadingBooking === "loading"}
      invoice={invoice}
    />
  );
};

export default SpaceInfoPaper;
