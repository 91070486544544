import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "@reach/router";
import { getConsultsThunk, getMoreConsultsThunk } from "../../redux/actions/consults-actions";
import { CONSULT_STATUS } from "../../redux/reducers";
import SideMenu from "../../components/brand-dashboard/side-menu";

const SideMenuContainer = () => {
  const location = useLocation();
  const parts = location.pathname.split("/");
  const indexTenantSegment = parts.indexOf("tenant");
  const indexChatSegment = parts.indexOf("chat");
  const bookingStatusByLocation =
    indexTenantSegment !== -1 && indexTenantSegment + 1 < parts.length
      ? parts[indexTenantSegment + 1]
      : localStorage.getItem("TenantLastBookingState") || CONSULT_STATUS.IN_PROGRESS;
  const bookingIdByLocation =
    indexChatSegment !== -1 && indexChatSegment + 1 < parts.length ? Number(parts[indexChatSegment + 1]) : 0;

  const dispatch = useDispatch();
  const consults = useSelector((state) => state.consults.data);
  const status = useSelector((state) => state.consults.status);
  const bookingState = useSelector((state) => state.consults.bookingState);
  const bookingSearch = useSelector((state) => state.consults.search);
  const hasMoreConsults = useSelector((state) => state.consults.hasNext);

  const onChangeBookingStatus = (state) => {
    dispatch(getConsultsThunk({ status: state, index: 1, search: bookingSearch }));
  };

  const loadMoreConsults = () => {
    dispatch(getMoreConsultsThunk({ status: bookingState, search: bookingSearch }));
  };

  const searchBookings = (search) => {
    dispatch(getConsultsThunk({ status: bookingState, index: 1, search }));
  };

  useEffect(() => {
    if (bookingStatusByLocation) {
      localStorage.setItem("TenantLastBookingState", bookingStatusByLocation);
    }
  }, [bookingStatusByLocation]);

  return (
    <SideMenu
      consults={consults}
      bookingId={bookingIdByLocation}
      status={status}
      initialStateValue={bookingStatusByLocation}
      initialSearchValue={bookingSearch}
      hasMoreConsults={hasMoreConsults}
      onChangeBookingStatus={onChangeBookingStatus}
      onLoadMoreConsults={loadMoreConsults}
      onSearchBookings={searchBookings}
    />
  );
};

export default SideMenuContainer;
