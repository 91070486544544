import { Box, Button, Hidden, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import Image from "material-ui-image";
import { formatDate, isBefore } from "../../utils/dateUtils";
import numbro from "numbro";
import PropTypes from "prop-types";
import React from "react";
import { useParams } from "@reach/router";
import { useNavigate } from "/src/hooks/navigation";
import RequestBookingButton from "../../containers/request-booking-button";
import { CONSULT_STATUS, CONSULT_TRANSLATIONS } from "../../redux/reducers";
import { convertToVirtualState, priceFromPaymentPlan } from "../../utils";
import ChainIcon from "../../icons/chain-icon";
// theme
import theme from "../../theme";
import Link from "./../link";
import { useLingui } from "@lingui/react";
import { Trans } from "@lingui/macro";
import { t } from "@lingui/macro";

import config from "../../config";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: "8px 0",
    display: "flex",
    cursor: "pointer",
    height: 126,
    paddingLeft: theme.spacing(2),
    paddingRight: 0,
    transition: "0.3s all ease-in",
    "&:hover": {
      backgroundColor: theme.palette.lightGray,
    },
    [theme.breakpoints.down("sm")]: {
      padding: "15px 0",
      alignItems: "center",
      height: "auto",
      paddingLeft: theme.spacing(0),
    },
  },
  imageContainer: {
    minWidth: 110,
    width: 110,
    height: 110,
    [theme.breakpoints.down("sm")]: {
      minWidth: 80,
      width: 80,
      height: 80,
      marginRight: theme.spacing(3),
    },
  },
  image: {
    objectFit: "cover",
    borderRadius: "50%",
    [theme.breakpoints.up("md")]: {
      borderRadius: 0,
    },
  },
  notificationIcon: {
    float: "right",
    width: 11,
    height: 11,
    color: theme.palette.primary.main,
    fontSize: "small",
    marginRight: theme.spacing(1 / 2),
    marginLeft: theme.spacing(1 / 2),
  },
  notificationIconContainer: {
    height: 20,
  },
  textContainer: {
    color: ({ hasPendingResponse }) =>
      hasPendingResponse ? theme.palette.black : theme.palette.gray,
  },
  buttonsContainer: {
    minWidth: 185,
    width: 185,
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
  },
  nextIcon: {
    fontSize: "medium",
    color: theme.palette.secondary.main,
    cursor: "pointer",
  },
  uppercase: {
    textTransform: "uppercase",
  },
  fontBold: {
    fontWeight: "bold",
  },
  text: {
    marginBottom: theme.spacing(1 / 2),
  },
}));

function CosultItem({ consult, currentUserId }) {
  const { navigate } = useNavigate();
  const { i18n } = useLingui();

  const params = useParams();
  const {
    space,
    status,
    start,
    end,
    id,
    user_last_seen,
    updated_at,
    payment_plan,
    show_prices_info,
    parent_id,
    confirmed_at,
  } = consult;
  const price = parseFloat(consult.price);
  const periodPrice = parseFloat(consult.periodPrice);
  const paymentPlan = payment_plan;
  const hasPendingResponse = user_last_seen
    ? isBefore(user_last_seen, updated_at)
    : false;

  const classes = useStyles({
    ...theme,
    hasPendingResponse,
  });
  const fontWeight = hasPendingResponse ? classes.fontBold : undefined;

  const handleClick = () =>
    navigate(`/tenant/${params.consultationStatus}/chat/${id}`);

  return (
    <React.Fragment>
      <Box className={classes.container} onClick={handleClick}>
        {hasPendingResponse ? (
          <FiberManualRecordIcon className={classes.notificationIcon} />
        ) : (
          <Box width="11px" height="11px" ml={1 / 2} mr={1 / 2}></Box>
        )}
        <Box p={0} className={classes.imageContainer}>
          {consult.spaces[0]?.attachments[0]?.url ? (
            <Image
              src={
                consult.spaces[0]?.attachments?.length
                  ? consult.spaces[0]?.attachments[0]?.url
                  : "/images/placeholder.png"
              }
              className={classes.image}
              imageStyle={{ objectFit: "cover" }}
            />
          ) : (
            <Image
              src={config.ADVERTISING_IMG || "/images/placeholder.png"}
              className={classes.image}
              imageStyle={{ objectFit: "cover" }}
            />
          )}
        </Box>
        <Box
          p={0}
          pl={[0, 3]}
          flexGrow={1}
          display={"flex"}
          flexDirection={"column"}
          className={classes.textContainer}
        >
          <Box>
            <Typography variant="subtitle1" className={classes.text}>
              {space ? space.title : consult.project_name}
              {show_prices_info && (
                <Hidden mdUp={true}>
                  {" "}
                  | {priceFromPaymentPlan(paymentPlan, price, periodPrice)}
                </Hidden>
              )}
            </Typography>
            <Typography
              variant="body1"
              className={classes.text}
              style={{ color: "inherit" }}
            >
              {consult?.asset?.city?.name} - {consult?.asset?.name}
            </Typography>
            <Box display="flex" flexDirection="row">
              <Typography
                variant="body1"
                className={classes.text}
                style={{ color: "inherit" }}
              >
                {formatDate(start, "D MMM YYYY")} -{" "}
                {formatDate(end, "D MMM YYYY")}{" "}
              </Typography>
              <Hidden smDown={true}>
                <Typography
                  variant="body1"
                  style={{
                    textTransform: "none",
                    marginLeft: 2,
                    color: "inherit",
                  }}
                  display={"inline"}
                >
                  por {numbro(price).formatCurrency()}
                </Typography>
              </Hidden>
            </Box>
            <Box display="flex" flexDirection="row" alignItems="center">
              {parent_id > 0 ? (
                <Box display="flex" alignItems="center" mr={1 / 2}>
                  <ChainIcon
                    style={{ width: "10px", height: "10px" }}
                    fill={theme.palette.black}
                  />
                </Box>
              ) : (
                <></>
              )}
              <Typography variant="body1" style={{ color: "inherit" }}>
                {`#${id} `}<Trans id={convertToVirtualState(status, start, end, confirmed_at)} />
              </Typography>
            </Box>
          </Box>
        </Box>
        <Hidden smDown={true}>
          <Box className={classes.buttonsContainer}>
            <Box
              flexDirection="flex-start"
              className={classes.notificationIconContainer}
            >
              {hasPendingResponse && (
                <FiberManualRecordIcon className={classes.notificationIcon} />
              )}
            </Box>
            <Box flexGrow={1} className={classes.buttonsContainer}>
              <Box mb={1} width="100%">
                {status === CONSULT_STATUS.PENDING ? (
                  <RequestBookingButton
                    title={space?.title}
                    start={start}
                    end={end}
                    price={price}
                    bookingId={id}
                  />
                ) : (
                  <Button
                    disabled={status !== CONSULT_STATUS.PENDING}
                    size="large"
                    variant="contained"
                    fullWidth={true}
                  >
                    <Trans>Reserva Solicitada</Trans>
                  </Button>
                )}
              </Box>
              <Box width="100%">
                <Link to={`/chat/${id}`} style={{ textDecoration: "none" }}>
                  <Button
                    size="large"
                    variant="contained"
                    color="secondary"
                    fullWidth={true}
                  >
                    <Trans>Ver Solicitud</Trans>
                  </Button>
                </Link>
              </Box>
            </Box>
          </Box>
        </Hidden>
        <Hidden mdUp={true}>
          <Box display={"flex"} alignItems={"center"}>
            <Box>
              <Link to={`/chat/${id}`}>
                <ArrowForwardIosIcon className={classes.nextIcon} />
              </Link>
            </Box>
          </Box>
        </Hidden>
      </Box>
    </React.Fragment>
  );
}

CosultItem.propTypes = {
  consult: PropTypes.object.isRequired,
  currentUserId: PropTypes.number.isRequired,
};

export default CosultItem;
