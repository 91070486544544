import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

export default function SearchIcon({ fill = "black", width = 20, height = 20,...rest }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 28 24"
      fill={fill}
      {...rest}
    >
      <path
        d="M26.8756 22.0449L21.7804 18.5853C23.492 16.1241 24.254 13.0045 23.6484 9.83372C22.6092 4.40212 17.8484 0.459717 12.3268 0.459717C11.6008 0.459717 10.868 0.529317 10.1492 0.666917C7.12199 1.24532 4.50119 2.96852 2.76959 5.51812C1.03839 8.06812 0.403586 11.1393 0.981986 14.1661C2.02039 19.5981 6.78119 23.5405 12.3028 23.5405C13.0292 23.5405 13.762 23.4709 14.4816 23.3333C16.994 22.8529 19.1604 21.5857 20.776 19.8369L25.9776 23.3685C26.1152 23.4621 26.2712 23.5069 26.426 23.5069C26.6824 23.5069 26.934 23.3841 27.0884 23.1561C27.3368 22.7909 27.2416 22.2929 26.8756 22.0449ZM14.1808 21.7613C13.5604 21.8801 12.9284 21.9401 12.3028 21.9401C7.54799 21.9401 3.44799 18.5445 2.55399 13.8653C2.05519 11.2581 2.60199 8.61252 4.09359 6.41652C5.58519 4.22052 7.84199 2.73652 10.4496 2.23812C11.07 2.11972 11.7016 2.05972 12.3272 2.05972C17.0816 2.05972 21.1824 5.45532 22.0772 10.1341C23.1056 15.5169 19.5632 20.7329 14.1808 21.7613Z"
        fill={fill}
      />
    </svg>
  );
}
