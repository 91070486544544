import { Box, Button, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import theme from "./../../theme";

const useStyles = makeStyles((theme) => ({
  textDesc: {
    textAlign: "center",
  },
}));

function OnePageMessage({
  title,
  desc,
  buttonName,
  action,
  showButton = true,
}) {
  const classes = useStyles(theme);

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      mt={12}
    >
      <Typography
        variant={"h2"}
        color="textPrimary"
        style={{ textAlign: "center" }}
      >
        {title}
      </Typography>
      <Box mt={2}>
        <Typography
          variant={"subtitle1"}
          color="textPrimary"
          className={classes.textDesc}
        >
          {desc}
        </Typography>
      </Box>
      {showButton && (
        <Box mt={2}>
          <Button
            size="large"
            variant="contained"
            color="primary"
            onClick={action}
          >
            {buttonName}
          </Button>
        </Box>
      )}
    </Box>
  );
}

export default OnePageMessage;
