import React, { useState, useEffect } from "react";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "./../text-field/text-field-alternative";
// theme
import theme from "./../../theme-admin";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  label: {
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  divider: {
    marginTop: theme.spacing(1),
    borderTop: "2px solid black",
  },
}));

export default ({
  fields = [],
  contractData = {},
  contractFieldsCategories = [],
}) => {
  const classes = useStyles(theme);

  return (
    <>
      {contractFieldsCategories.map((f) => (
        <Box
          display={"flex"}
          flexDirection="column"
          mt={2}
          key={f}
          width="100%"
        >
          <Box mt={2} mb={2} width="100%">
            <Typography
              variant="subtitle1"
              style={{ fontWeight: "bold", textTransform: "uppercase" }}
            >
              {f}
            </Typography>
            <Box className={classes.divider}></Box>
          </Box>
          {fields
            .filter((fld) => fld.category === f)
            .sort((a, b) => a - b)
            .map((fld) => (
              <Box display="flex">
                <Typography variant={"subtitle1"} className={classes.label}>
                  {fld.description}:
                </Typography>
                <Typography variant={"body1"}>
                  {contractData[fld.name]}
                </Typography>
              </Box>
            ))}
        </Box>
      ))}
      <Box display={"flex"} flexDirection="column" mt={2} width="100%">
        <Box mt={2} mb={2} width="100%">
          <Box className={classes.divider}></Box>
        </Box>
        {fields
          .filter((fld) => fld.category === null || fld.category === undefined)
          .sort((a, b) => a - b)
          .map((fld) => (
            <Box display="flex">
              <Typography variant={"subtitle1"} className={classes.label}>
                {fld.description}:
              </Typography>
              <Typography variant={"body1"}>
                {contractData[fld.name]}
              </Typography>
            </Box>
          ))}
      </Box>
    </>
  );
};
