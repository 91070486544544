import { Box, Typography, Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React, { useState } from "react";
import Image from "material-ui-image";
import { Trans } from "@lingui/macro";
import { formatDateUtc } from "../../utils/dateUtils";
import { priceFromPaymentPlan, convertToVirtualState } from "../../utils";
import ChainIcon from "../../icons/chain-icon";
// theme
import theme from "../../theme";

const useStyles = makeStyles((th) => ({
  bookingCard: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    width: "95%",
    height: "105px",
    marginTop: th.spacing(1),
    padding: th.spacing(1 / 2),
  },
  notificationIcon: {
    width: "5px",
    height: "5px",
    backgroundColor: th.palette.primary.main,
    borderRadius: "50%",
  },
}));

function ConsultCard({
  isSelected = false,
  bookingId = 0,
  title = "",
  paymentPlan,
  price = 0,
  periodPrice = 0,
  showPrices,
  country = "",
  asset = "",
  start = "",
  end = "",
  longTerm = false,
  bookingState = "",
  image = "",
  parentId = 0,
  hasNotification = false,
  confirmedAt,
}) {
  const classes = useStyles({
    ...theme,
  });

  const [onMouseOverCardId, setOnMouseOverCardId] = useState(-1);

  return (
    <Paper
      onMouseEnter={() => setOnMouseOverCardId(bookingId)}
      onMouseLeave={() => setOnMouseOverCardId(-1)}
      elevation={onMouseOverCardId === bookingId || isSelected ? 3 : 0}
      style={{
        borderLeft: isSelected ? `4px solid ${theme.palette.primary.main}` : "none",
      }}
      className={classes.bookingCard}
    >
      <Image
        src={image || "/images/crenex-logo-light.jpg"}
        style={{
          height: "64px",
          width: "64px",
          padding: "0",
        }}
        imageStyle={{
          objectFit: "cover",
          height: "100%",
          width: "100%",
          borderRadius: "50%",
        }}
      />
      <Box width="200px" ml={1}>
        <Typography
          variant="body1"
          style={{
            fontWeight: "bold",
            color: isSelected || hasNotification ? theme.palette.text.primary : theme.palette.grayC4,
          }}
        >
          {`${title.length > 20 ? `${title.substring(0, 20)}...` : title}`}
        </Typography>
        <Typography
          variant="body1"
          style={{
            fontWeight: "bold",
            color: isSelected || hasNotification ? theme.palette.text.primary : theme.palette.grayC4,
          }}
        >
          {showPrices ? priceFromPaymentPlan(paymentPlan, price, periodPrice) : ""}
        </Typography>
        <Typography
          variant="body1"
          style={{
            color: isSelected || hasNotification ? theme.palette.text.primary : theme.palette.grayC4,
          }}
        >{`${asset} - ${country}`}</Typography>
        <Typography
          variant="body1"
          style={{
            color: isSelected || hasNotification ? theme.palette.text.primary : theme.palette.grayC4,
          }}
        >
          {`${formatDateUtc(start, "DD/MM/YYYY").toUpperCase()}`}
          <span> - </span>
          {longTerm ? <Trans>Estadía prolongada</Trans> : `${formatDateUtc(end, "DD/MM/YYYY").toUpperCase()}`}
        </Typography>
        <Box display="flex" flexDirection="row" alignItems="center">
          {parentId > 0 ? (
            <Box display="flex" alignItems="center" mr={1 / 2}>
              <ChainIcon
                style={{
                  width: "10px",
                  height: "10px",
                }}
                fill={isSelected || hasNotification ? theme.palette.text.primary : theme.palette.grayC4}
              />
            </Box>
          ) : (
            <></>
          )}
          <Typography
            variant="body1"
            style={{
              fontWeight: "bold",
              color: isSelected || hasNotification ? theme.palette.text.primary : theme.palette.grayC4,
            }}
          >
            <span>{`#${bookingId} `}</span>
            <Trans id={convertToVirtualState(bookingState, start, end, confirmedAt)} />
          </Typography>
        </Box>
      </Box>
      {hasNotification && !isSelected && (
        <Box mr={1 / 2} height="100%" display="flex" alignItems="flex-start" justifyContent="flex-start">
          <Box mt={1} className={classes.notificationIcon} />
        </Box>
      )}
    </Paper>
  );
}

export default ConsultCard;
