import { Box, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import { TextValidator } from "react-material-ui-form-validator";
// theme
import theme from "./../../theme";

const useStyles = makeStyles((theme) => ({
  label: {
    fontSize: theme.typography.body1.fontSize,
    transform: "translate(0, -5px) scale(1)",
    fontWeight: "bold",
  },
  descriptionLabel: {
    color: theme.palette.text.primary,
    fontSize: theme.typography.body1.fontSize,
    fontWeight: "bold",
    marginBottom: theme.spacing(1 / 2),
  },
  inputContainer: {
    position: "relative",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    "& > div": {
      width: "100%",
    },
  },
  input: {
    borderRadius: 0,
    backgroundColor: theme.palette.lightGray,
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    height: "46px",
    "& > input": {
      "&:after": {
        "& .MuiInput-underline:after": {
          position: "fixed",
        },
      },
    },
  },
}));

const MyTextField = ({ InputProps, description, ...rest }) => {
  const classes = useStyles(theme);
  return (
    <Box className={classes.inputContainer}>
      {description && (
        <Box mt={2}>
          <Typography variant="body2" className={classes.descriptionLabel}>
            {description}
          </Typography>
        </Box>
      )}
      <TextValidator
        className={classes.field}
        InputLabelProps={{
          shrink: true,
          className: classes.label,
        }}
        InputProps={{
          disableUnderline: true,
          className: `${classes.input} ${
            description ? classes.forceDescription : ""
          }`,
          variant: "standard",
          ...InputProps,
        }}
        {...rest}
      />
    </Box>
  );
};

export default MyTextField;
