import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ShowTenantContractDataComponent from "../../components/sign-consultation/show-tenant-contract-data";

function ShowTenantContractDataContainer() {
  const localContractData = useSelector(
    (state) => state.consultation.localContractData
  );
  const customerTemplateFields = useSelector(
    (state) => state.consultation.data.contractModel.customerTemplateFields
  );

  const [contractFieldsCategories, setContractFieldsCategories] = useState();

  useEffect(() => {
    const contractCategories = [];
    if (customerTemplateFields) {
      Object.keys(customerTemplateFields).forEach((tf) => {
        if (
          !contractCategories.includes(customerTemplateFields[tf].category) &&
          customerTemplateFields[tf].category
        ) {
          contractCategories.push(customerTemplateFields[tf].category);
        }
      });
      setContractFieldsCategories(contractCategories);
    }
  }, [customerTemplateFields]);

  return (
    <ShowTenantContractDataComponent
      fields={customerTemplateFields}
      contractData={localContractData}
      contractFieldsCategories={contractFieldsCategories}
    />
  );
}

export default ShowTenantContractDataContainer;
