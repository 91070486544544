import { Box, Container, Hidden, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import { useLocation, Redirect } from "@reach/router";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { Trans } from "@lingui/macro";
import { CONSULT_STATUS } from "../../redux/reducers";
import ChatConsultation from "../../containers/chat-consultation";
import PayConsultation from "../../containers/pay-consultation";
import SignConsultation from "../../containers/sign-consultation";
import SpaceCard from "../../containers/space-card";
import SideMenu from "../../containers/brand-dashboard/side-menu";
import { isMobile } from "../../utils";
import Link from "../link";
import PrivateRoute from "../../private-route";
import ConsultsContainer from "../../containers/consults";
// theme
import theme from "../../theme";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    paddingTop: theme.spacing(2),
    height: "100%",
    [theme.breakpoints.down("xs")]: {
      padding: 0,
    },
  },
  mainContainer: {
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  sidebar: {
    width: 320,
    flexDirection: "column",
    paddingRight: theme.spacing(2),
    display: ({ hideSidebar }) => (hideSidebar ? "none" : "flex"),
    [theme.breakpoints.down("sm")]: {
      flexDirection: "row",
      width: "100%",
      overflow: "auto",
    },
    "& > a": {
      width: "100%",
      height: 55,
      fontSize: 14,
      display: "flex",
      alignItems: "center",
      textAlign: "left",
      padding: theme.spacing(2),
      textDecoration: "none",
      whiteSpace: "nowrap",
      borderBottom: `1px solid ${theme.palette.disabledRow}`,
      color: theme.palette.gray71,
      cursor: "pointer",
      transition: "0.3s all ease-in",
      fontWeight: 700,
      "&:hover": {
        backgroundColor: theme.palette.primary.light,
        color: theme.palette.primary.contrastText,
      },
      "&.active": {
        backgroundColor: theme.palette.primary.main,
        borderBottom: 0,
        color: theme.palette.primary.contrastText,
      },
    },
  },
  main: {
    flex: 5,
  },
}));

const BrandDashboard = () => {
  const path = "/tenant";
  const location = useLocation();
  const smallScreen = useMediaQuery(theme.breakpoints.down("xs"));
  const defaultState =
    (typeof localStorage !== "undefined" && localStorage.getItem("TenantLastBookingState")) ||
    CONSULT_STATUS.IN_PROGRESS;

  const hideSidebar = () => {
    if (smallScreen) {
      return (
        location.pathname.includes("sign/") || location.pathname.includes("pay/") || location.pathname.includes("chat/")
      );
    }
    return location.pathname.includes("sign/") || location.pathname.includes("pay/");
  };

  const classes = useStyles({
    ...theme,
    hideSidebar: hideSidebar(location.pathname),
  });

  const chatRedirect = () => {
    if (isMobile()) {
      return `${location.pathname}/${defaultState}/1`;
    }

    return `${location.pathname}/${defaultState}/chat`;
  };

  return (
    <Container className={classes.container}>
      <SpaceCard />
      <Box className={classes.mainContainer}>
        <Box className={classes.sidebar}>
          <Hidden mdUp>
            <Link to={`${path}/${CONSULT_STATUS.IN_PROGRESS}/1`}>
              <Typography variant="subtitle1">
                <Trans>Consultas</Trans>
              </Typography>
            </Link>
            <Link to={`${path}/${CONSULT_STATUS.TO_BE_STARTED}/1`}>
              <Typography variant="subtitle1">
                <Trans>Reservas por comenzar</Trans>
              </Typography>
            </Link>
            <Link to={`${path}/${CONSULT_STATUS.ACTIVE}/1`}>
              <Typography variant="subtitle1">
                <Trans>Reservas activas</Trans>
              </Typography>
            </Link>
            <Link to={`${path}/${CONSULT_STATUS.DONE}/1`}>
              <Typography variant="subtitle1">
                <Trans>Reservas finalizadas</Trans>
              </Typography>
            </Link>
            <Link to={`${path}/${CONSULT_STATUS.CANCELED}/1`}>
              <Typography variant="subtitle1">
                <Trans>Canceladas</Trans>
              </Typography>
            </Link>
            <a href="/" target="_blank" className={classes.link} rel="noreferrer">
              <Typography variant="subtitle1">
                <Trans>Nueva búsqueda</Trans>
              </Typography>
            </a>
          </Hidden>
          <Hidden smDown>
            <SideMenu />
          </Hidden>
        </Box>
        <Box className={classes.main}>
          <PrivateRoute basePath={path}>
            <>
              <ChatConsultation path=":consultationStatus/chat" />
              <ChatConsultation path=":consultationStatus/chat/:booking" />
            </>
            <SignConsultation path=":consultationStatus/sign/:booking" />
            <PayConsultation path=":consultationStatus/pay/:booking/:invoiceId" />
            <ConsultsContainer path=":consultationStatus/:page" />
            <Redirect noThrow from="/" to={chatRedirect()} />
          </PrivateRoute>
        </Box>
      </Box>
    </Container>
  );
};

export default BrandDashboard;
