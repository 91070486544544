import numbro from "numbro";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "@reach/router";
import { useNavigate } from "/src/hooks/navigation";

import SignConsultation from "../../components/sign-consultation";
import {
  getBookingThunk,
  contractSignGenerateThunk,
  updateContractDataThunk,
} from "../../redux/actions/consultation-actions";

const SignConsultationContainer = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const { navigate } = useNavigate();

  const [step, setStep] = useState(1);
  const [contractFieldsCategories, setContractFieldsCategories] = useState([]);

  const state = useSelector((state) => state.consultation.data.state);
  const contractCallback = useSelector(
    (state) => state.consultation.data.contractCallback
  );
  const updateContractDataStatus = useSelector(
    (state) => state.consultation.updateContractDataStatus
  );
  const signStatus = useSelector((state) => state.consultation.signStatus);
  const contractModel = useSelector(
    (state) => state.consultation.data.contractModel
  );
  const contractData = useSelector(
    (state) => state.consultation.data.contractData
  );

  const handleGoToPaymentMethod = () => {
    navigate(`/tenant/inprogress/pay/${params.booking}`);
  };

  const handleSaveContractData = () =>
    dispatch(updateContractDataThunk(params.booking, "customer"));
  const handleGoToStart = () => setStep(1);
  const handleSignContract = () =>
    dispatch(contractSignGenerateThunk({ bookingId: params.booking }));

  useEffect(() => {
    const contractCategories = [];
    if (contractModel.template_fields) {
      Object.keys(contractModel.template_fields).forEach((tf) => {
        if (
          !contractCategories.includes(
            contractModel.template_fields[tf].category
          ) &&
          contractModel.template_fields[tf].category
        ) {
          contractCategories.push(contractModel.template_fields[tf].category);
        }
      });
      setContractFieldsCategories(contractCategories);
    }
  }, [contractModel.template_fields]);

  useEffect(() => {
    dispatch(getBookingThunk({ bookingId: params.booking }));
    setStep(1);
  }, [params.booking, dispatch]);

  useEffect(() => {
    if (updateContractDataStatus === "success") {
      setStep(2);
    }
  }, [updateContractDataStatus]);

  useEffect(() => {
    if (signStatus === "success") {
      setStep(3);
    }
  }, [signStatus]);

  return (
    <SignConsultation
      bookingId={params.booking}
      contractFieldsCategories={contractFieldsCategories}
      state={state}
      adminContractCallback={contractCallback ? contractCallback["admin"] : {}}
      contractModel={contractModel}
      goToPayment={handleGoToPaymentMethod}
      contractData={contractData}
      step={step}
      onSaveContractData={handleSaveContractData}
      updateContractDataStatus={updateContractDataStatus}
      onGoToStart={handleGoToStart}
      onSignContract={handleSignContract}
      signStatus={signStatus}
    />
  );
};

export default SignConsultationContainer;
