import { Box, Typography, Divider, Button, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import DownloadIcon from "../../icons/download-icon";
import React, { useState, useEffect } from "react";
import { CONSULT_STATUS } from "../../redux/reducers";
import SuccessIcon from "../../icons/success-icon";
import { Trans } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { t } from "@lingui/macro";
// theme
import theme from "./../../theme";
// local components
import Link from "./../link";
import BackLongIcon from "./../../icons/back-long-icon";
import TenantContractTemplateFieldContainer from "../../containers/sign-consultation/tenant-contract-template-field";
import { ValidatorForm } from "react-material-ui-form-validator";
import ShowTenantContractData from "./../../containers/sign-consultation/show-tenant-contract-data";
import CheckIcon from "../../icons/success-circle-icon";

const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
    height: "100%",
    marginBottom: 50,
    [theme.breakpoints.down("xs")]: {
      marginBottom: 0,
      padding: theme.spacing(2),
    },
  },
  signFooter: {
    display: "flex",
    height: "80px",
    backgroundColor: theme.palette.disabledRow,
    borderTop: "1px solid black",
    alignItems: "center",
    justifyContent: "center",
  },
  goToPaymentSection: {
    display: "flex",
    flexDirection: "column",
    height: "102px",
    alignItems: "center",
    justifyContent: "center",
    marginTop: theme.spacing(2),
    border: theme.borders[11],
    width: "100%",
  },
  header: {
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      width: "100%",
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
  uploadResponse: {
    display: "flex",
    flexDirection: "row",
    minHeight: "102px",
    alignItems: "center",
    justifyContent: "center",
    marginTop: theme.spacing(2),
    border: theme.borders[12],
    padding: theme.spacing(2),
    backgroundColor: theme.palette.success.main,
    color: theme.palette.success.text,
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
    },
  },
  fontBold: {
    fontWeight: 700,
    color: theme.palette.text.main,
  },
  form: {
    width: "100%",
    display: "flex",
    flexWrap: "wrap",
  },
  divider: {
    marginTop: theme.spacing(1),
    borderTop: "2px solid black",
  },
}));

export default function SignConsultation({
  bookingId,
  contractFieldsCategories = [],
  step = 1,
  state,
  adminContractCallback = {},
  goToPayment,
  contractModel,
  onSaveContractData,
  updateContractDataStatus,
  onGoToStart,
  onSignContract,
  signStatus,
}) {
  const classes = useStyles(theme);
  const { i18n } = useLingui();

  const FieldWithoutCategory = () => (
    <Box mt={2} mb={2}>
      <Box className={classes.divider}></Box>
      <Box display={"flex"} className={classes.form}>
        <Grid container>
          {contractModel.customerTemplateFields
            .filter((f) => f.category === null || f.category === undefined)
            .sort((a, b) => Number(a.order) - Number(b.order))
            .map((ctf) => (
              <Grid
                sm={6}
                xs={12}
                style={{
                  display: "flex",
                  alignItems: "flex-end",
                }}
              >
                <Box display={"flex"} className={classes.form}>
                  <TenantContractTemplateFieldContainer
                    key={ctf.name}
                    field={ctf}
                  />
                </Box>
              </Grid>
            ))}
        </Grid>
      </Box>
    </Box>
  );

  const CategoriesFields = ({ category }) => (
    <>
      {contractModel.customerTemplateFields.filter(
        (f) => f.category === category
      ).length > 0 && (
        <Box mt={2} mb={2}>
          <Typography variant="subtitle1">{category}</Typography>
          <Box className={classes.divider}></Box>
          <Box display={"flex"} className={classes.form}>
            <Grid container>
              {contractModel.customerTemplateFields
                .filter((f) => f.category === category)
                .sort((a, b) => Number(a.order) - Number(b.order))
                .map((ctf) => (
                  <Grid
                    sm={6}
                    xs={12}
                    style={{
                      display: "flex",
                      alignItems: "flex-end",
                    }}
                  >
                    <Box display={"flex"} className={classes.form}>
                      <TenantContractTemplateFieldContainer
                        key={ctf.name}
                        field={ctf}
                      />
                    </Box>
                  </Grid>
                ))}
            </Grid>
          </Box>
        </Box>
      )}
    </>
  );

  return (
    <Box
      display={"flex"}
      width={["100%", "800px"]}
      margin={"0 auto"}
      flexDirection={"column"}
    >
      {state === CONSULT_STATUS.ACCEPTED && step === 1 && (
        <>
          <Box className={classes.header}>
            <Link to={`/tenant/inprogress/chat/${bookingId}`}>
              <Typography
                variant={"subtitle1"}
                style={{
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                }}
              >
                <BackLongIcon /> <Trans> VOLVER</Trans>
              </Typography>
            </Link>
          </Box>
          <Box className={classes.container}>
            <Typography variant="h1" className={classes.fontBold}>
              <Trans>Completar datos del contrato</Trans>
            </Typography>
            <Typography variant="body1">
              <Trans>
                Antes de proceder a la firma, necesitamos los siguientes datos
                para que podamos generar el contrato correctamente.
              </Trans>
            </Typography>
            <ValidatorForm onSubmit={onSaveContractData}>
              {contractFieldsCategories.map((cfc) => (
                <CategoriesFields category={cfc} />
              ))}
              <FieldWithoutCategory />
              <Box className={classes.signFooter} mt={3}>
                {adminContractCallback.status === "done" ? (
                  <Link
                    href={adminContractCallback.link}
                    target={"_blank"}
                    variant="contained"
                    color="primary"
                  >
                    <Trans>Descargar contrato original</Trans>
                    <DownloadIcon
                      fill={theme.palette.black}
                      style={{ marginLeft: 5 }}
                    />
                  </Link>
                ) : (
                  <Typography variant={"body1"}>
                    <Trans>
                      La previsualización del contrato aún no está disponible
                    </Trans>
                  </Typography>
                )}
                <Button
                  variant="contained"
                  color="secondary"
                  type={"submit"}
                  style={{ marginLeft: 10 }}
                  disabled={updateContractDataStatus === "loading"}
                >
                  <Trans>Continuar</Trans>
                </Button>
              </Box>
            </ValidatorForm>
            {updateContractDataStatus === "error" && (
              <Box>
                <Typography variant={"body1"} color={"error"}>
                  <Trans>
                    Ha ocurrido un error al intentar guardar los datos
                    ingresados. Intente nuevamente.
                  </Trans>
                </Typography>
              </Box>
            )}
          </Box>
        </>
      )}
      {state === CONSULT_STATUS.ACCEPTED && step === 2 && (
        <Box className={classes.container}>
          <Typography variant="h1" className={classes.fontBold}>
            <Trans>Datos recibidos</Trans>
          </Typography>
          <Box mt={2}>
            <Typography variant={"body1"}>
              <Trans>
                Revisa los siguientes datos ingresados. Si es son correctos,
                procede a la firma digital.
              </Trans>
            </Typography>
          </Box>
          <ShowTenantContractData />
          {signStatus === "error" && (
            <Box>
              <Typography variant={"body1"} color={"error"}>
                <Trans>
                  Ha ocurrido un error. Intenta nuevamente por favor.
                </Trans>
              </Typography>
            </Box>
          )}
          <Box className={classes.signFooter} mt={3}>
            <Button variant="text" color="primary" onClick={onGoToStart}>
              <Trans>Volver</Trans>
            </Button>
            <Button
              variant="contained"
              color="primary"
              style={{ marginLeft: 10 }}
              onClick={onSignContract}
            >
              <Trans>Continuar</Trans>
            </Button>
          </Box>
        </Box>
      )}
      {state === CONSULT_STATUS.ACCEPTED && step === 3 && (
        <Box p={1}>
          <Box className={classes.uploadResponse}>
            <Box mr={2}>
              <CheckIcon fill={theme.palette.white} width={56} height={56} />
            </Box>
            <Box mt={1}>
              <Typography
                variant="subtitle1"
                style={{ fontWeight: "600", color: theme.palette.white }}
              >
                <Trans>
                  El proceso de generación de contrato ha comenzado
                  satisfactoriamente.
                </Trans>
              </Typography>
              <Typography
                variant={"body1"}
                style={{ color: theme.palette.white }}
              >
                <Trans>
                  En unos instantes podrás proceder a la firma del contrato con
                  los datos finales. Mientras tanto, puedes acceder al chat para
                  ultimar detalles con el gestor.
                </Trans>
              </Typography>
            </Box>
          </Box>
          <Box className={classes.goToPaymentSection}>
            <Link to={`/tenant/inprogress/chat/${bookingId}`}>
              <Button variant="contained" color="primary">
                <Trans>Volver al chat</Trans>
              </Button>
            </Link>
          </Box>
        </Box>
      )}
      {state === CONSULT_STATUS.SIGNED && (
        <Box p={1}>
          <Box className={classes.uploadResponse}>
            <SuccessIcon
              fill={theme.palette.success.main}
              fillBorder={theme.palette.white}
              style={{
                width: 35,
                height: 35,
                background: theme.palette.white,
                borderRadius: "50%",
              }}
            />
            <Box mt={1}>
              <Typography
                variant="body1"
                style={{ fontWeight: "600", color: theme.palette.white }}
              >
                <Trans>El contrato ha sido cumplimentado correctamente</Trans>
              </Typography>
            </Box>
          </Box>
          <Box className={classes.goToPaymentSection}>
            <Box width="187px">
              <Button
                onClick={goToPayment}
                fullWidth
                variant="contained"
                color="primary"
              >
                <Trans>IR AL PAGO</Trans>
              </Button>
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
}
