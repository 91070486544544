import React from "react";
import { useSelector, useDispatch } from "react-redux";
import ContractTemplateFieldComponent from "../../components/sign-consultation/tenant-contract-template-field";
import {
  modifyLocalContractData,
} from "../../redux/actions/consultation-actions";

function TenantContractTemplateFieldContainer({ field, disabled }) {
  const dispatch = useDispatch();

  const localContractData = useSelector((state) => state.consultation.localContractData[field.name]);

  const handleModifyLocalContractData = (value) => dispatch(modifyLocalContractData(field.name, value));

  return (
    <ContractTemplateFieldComponent
      field={field}
      disabled={disabled}
      onChangeValue={handleModifyLocalContractData}
      value={localContractData || ""}
    />
  );
}

export default TenantContractTemplateFieldContainer;

