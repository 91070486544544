import React, { useState, useEffect } from "react";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "./../text-field/text-field-alternative";
// theme
import theme from "./../../theme-admin";

const useStyles = makeStyles((theme) => ({
  customInput: {
    padding: 0,
    marginRight: theme.spacing(1),
    minWidth: "45%",
    flex: 1,
    "& .MuiInput-root": {
      border: theme.borders[1],
      padding: 0,
      paddingLeft: theme.spacing(1),
      height: "29px",
    },
    "& .MuiInputBase-input": {
      padding: 0,
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginLeft: 0,
    },
  },
  customInputLong: {
    flex: 2,
    minWidth: "100%",
    "& .MuiInput-root": {
      height: 100,
    },
  },
}));

export default ({ field, value, disabled, onChangeValue }) => {
  const classes = useStyles(theme);

  const handleChangeValue = (evt) => onChangeValue(evt.target.value);

  return (
    <>
      {field.type === "text" && (
        <Box className={classes.customInput}>
          <TextField
            type="text"
            fullWidth
            description={field.description}
            value={value}
            disabled={disabled}
            onChange={handleChangeValue}
            required
          />
        </Box>
      )}
      {field.type === "long_text" && (
        <Box
          className={[classes.customInput, classes.customInputLong].join(" ")}
        >
          <TextField
            multiline
            rows={5}
            fullWidth
            description={field.description}
            disabled={disabled}
            value={value}
            onChange={handleChangeValue}
            required
          />
        </Box>
      )}
    </>
  );
};
